import React from "react";
import theme from "theme";
import { Theme, Link, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				AdaSoft LTD
			</title>
			<meta name={"description"} content={"AdaSoft LTD | Software & Web Development"} />
			<meta property={"og:title"} content={"AdaSoft LTD"} />
			<meta property={"og:description"} content={"AdaSoft LTD | Software & Web Development"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/60da14fa3f4eb1001ea27689/images/270x270.png?v=2021-10-07T09:34:41.365Z"} />
			<meta name={"msapplication-TileColor"} content={"#04080C"} />
		</Helmet>
		<Section
			padding="50px 0 100px 0"
			quarkly-title="AdaSoft Hero"
			height="1080px"
			display="block"
			color="#171717"
			background="#171717"
		>
			<Image
				width="70%"
				src="https://uploads.quarkly.io/64a350028bd7e600185a6432/images/ADASOFT.svg?v=2023-07-03T22:48:28.683Z"
				align-self="center"
				height="600px"
				margin="0px 0px 80px 0px"
				flex="0 0 auto"
				sm-height="200px"
				quarkly-title="AdaSoft_Logo"
			/>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"60da14fa3f4eb1001ea27687"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});