export default {
	"pages": {
		"root": {
			"pageUrl": "root",
			"name": "root",
			"children": [
				"64a350038bd7e600185a6441",
				"64a350038bd7e600185a6445"
			],
			"id": "root"
		},
		"64a350038bd7e600185a6441": {
			"id": "64a350038bd7e600185a6441",
			"name": "404",
			"pageUrl": "404"
		},
		"64a350038bd7e600185a6445": {
			"id": "64a350038bd7e600185a6445",
			"name": "index",
			"pageUrl": "index"
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {
			"background": "--color-dark"
		},
		"seo": {
			"title": "AdaSoft LTD",
			"og:image": "https://uploads.quarkly.io/64a350028bd7e600185a6432/images/ADASOFT.svg?v=2023-07-03T22:48:28.683Z",
			"og:title": "AdaSoft LTD",
			"favicon_32px": "https://uploads.quarkly.io/60da14fa3f4eb1001ea27689/images/32x32.png?v=2021-10-07T09:34:24.098Z",
			"favicon_win10_270px": "https://uploads.quarkly.io/60da14fa3f4eb1001ea27689/images/270x270.png?v=2021-10-07T09:34:41.365Z",
			"background_win10": "#04080C",
			"description": "AdaSoft LTD | Software & Web Development",
			"og:description": "AdaSoft LTD | Software & Web Development",
			"favicon_apple_152px": "https://uploads.quarkly.io/60da14fa3f4eb1001ea27689/images/152x152.png?v=2021-10-07T09:34:31.980Z",
			"robotsFileType": "autogenerated",
			"sitemapFileType": "autogenerated",
			"customSitemapDomain": "https://AdaSoftLTD.co.uk"
		}
	}
}